<template>
  <div class="anmelden">
    <div class="row">
      <div class="flex md12">
        <va-card outlined>
          <template slot="header">
            <h5 class="mt-0 mb-0">{{ $t('anmelden.title') }}</h5>
          </template>
          <div class="row">
            <va-notification class="flex md10" v-if="currState.msgType=='success'" color="success">
              <va-badge color="success">
                {{ $t('common.notificationSuccess') }}
              </va-badge>
              {{ currState.msg }}
            </va-notification>
            <va-notification class="flex md10" v-if="currState.msgType=='error'" color="danger">
              <va-badge color="danger">
                {{ $t('common.notificationError') }}
              </va-badge>
              {{ currState.msg }}
            </va-notification>
          </div>
        </va-card>
      </div>
    </div>
    <va-modal v-model="toDelete.showDeleteModal"
      :message="toDelete.msg"
      title="$t('anmelden.signout')"
      :ok-text="$t('common.yes')"
      :cancel-text="$t('common.no')"
      @ok="deleteMyBauEvent()"/>
    <div class="row" v-for="(ev,evK) in workEventList" :key="evK">
      <div class="flex md12">
        <va-card :color="ev.workersCount > 0 ? '#cceecc' : ''" gradient>
          <div class="row">
            <div class="flex md3">
              {{ev.eventStart}} - {{ev.eventEnd}}
            </div>
            <div class="flex md3">
              {{ev.eventDescr}}
            </div>
            <div class="flex md1 flex-center">
              {{ev.sumWorkersCount}}
            </div>
            <div v-if="ev.workersCount">
              {{ $t('anmelden.subscribeWith') }}:
              <va-button small color="info" @click="signupDecr(ev)" class="mr-3 pr-2 pl-2">-</va-button>
              <b>{{ ev.workersCount }}</b>
              <va-button small color="info" @click="signupIncr(ev)" class="ml-3 mr-3 pr-2 pl-2">+</va-button>
              {{ $t('anmelden.persons') }}
            </div>
          </div>
          <div class="row align--center">
            {{ $t('anmelden.bookOtherUser') }}
            <va-input class="flex md3" v-model="ev.otherUser" :placeholder="currentUser" @input="setEventModified(ev)" />
            <va-input class="flex" v-model="ev.comments" :placeholder="$t('anmelden.comments')" @input="setEventModified(ev)" />
            <div class="flex md1">
              <va-button small v-if="!ev.workersCount" @click="signup(ev)"> {{ $t('anmelden.signUp') }}</va-button>
              <va-button small v-if="ev.isDirty" @click="saveMyBauEvent(ev)"> {{ $t('common.save') }}</va-button>
            </div>
            <div class="flex">
              <va-button small v-if="ev.workersCount" color="danger" @click="askDeleteEvent(ev)"> {{ $t('anmelden.signOut') }}</va-button>
            </div>
          </div>

        </va-card>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'anmelden',
  data () {
    this.$srvconn.sendMessage('getBauUserEvents')
      .then(response => {
        if (response.msgType === 'getBauUserEvents') {
          this.workEventList = response.userData.bauEvents
        }
        // console.log(response)
      })
      .catch(err => {
        console.log(err)
      })

    return {
      workEventList: {},
      currState: {
        msgType: '',
        msg: '',
      },
      toDelete: {
        showDeleteModal: false,
        msg: '',
        userEventID: -1,
      },
      currentUser: this.$userauth.loggedInUser(),
    }
  },
  methods: {
    mounted () {
    },
    saveMyBauEvent (ev) {
      var usrData = {
        userEventID: ev.userEventID,
        eventID: ev.eventID,
        workersCount: ev.workersCount,
        otherUser: ev.otherUser,
        comments: ev.comments,
      }
      this.$srvconn.sendMessage('saveMyBauEvent', usrData)
        .then(response => {
          if (response.msgType === 'error') {
            this.currState.msgType = 'error'
            this.currState.msg = response.userData
          } else {
            this.currState.msgType = 'success'
            this.currState.msg = this.$t('common.saveSuccess')
            ev.userEventID = response.userData.userEventID
            ev.isDirty = false
          }
        })
        .catch(err => {
          this.currState.msgType = 'error'
          this.currState.msg = err
        })
    },
    setEventModified (ev) {
      ev.isDirty = true
    },
    askDeleteEvent (ev) {
      this.toDelete.msg = ev.eventDescr + '  -  ' + this.$t('anmelden.reallySignout')
      this.toDelete.userEvent = ev
      this.toDelete.showDeleteModal = true
    },
    deleteMyBauEvent () {
      if (this.toDelete.userEvent) {
        var usrData = {
          userEventID: this.toDelete.userEvent.userEventID,
        }
        this.$srvconn.sendMessage('deleteMyBauEvent', usrData)
          .then(response => {
            if (response.msgType === 'error') {
              this.currState.msgType = 'error'
              this.currState.msg = response.userData
            } else {
              this.currState.msgType = 'success'
              this.currState.msg = this.$t('common.saveSuccess')
              this.toDelete.userEvent.commments = ''
              this.toDelete.userEvent.sumWorkersCount -= this.toDelete.userEvent.workersCount
              this.toDelete.userEvent.workersCount = 0
              this.toDelete.userEvent = null
              this.toDelete.msg = ''
            }
          })
          .catch(err => {
            console.log('ERROR: ' + err)
            this.currState.msgType = 'error'
            this.currState.msg = err
          })
      }
    },
    signup (ev) {
      ev.workersCount = 1
      ev.sumWorkersCount++
      this.saveMyBauEvent(ev)
    },
    signupIncr (ev) {
      ev.workersCount++
      ev.sumWorkersCount++
      ev.isDirty = true
    },
    signupDecr (ev) {
      if (ev.workersCount > 1) {
        ev.workersCount--
        ev.sumWorkersCount--
        ev.isDirty = true
      }
    },
  },
}
</script>

<style>
  .anmelden .va-card {
    color: #000000 !important;
  }
</style>
